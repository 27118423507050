<template>
  <div class="intro">
    <p>
      Artificial Intelligence techniques are being developed at an accelerating
      pace. Statistical learning, Natural Language Understanding, Image
      Segmentation, Information Extraction, Geospatial Analytics and other
      Machine Intelligence algorithms blur the boundary between feasible and
      infeasible. While AI is mostly used to optimize marketing campaigns
      and profile users, these powerful tools remain inaccessible to people.
    </p>
    <p>
      At Hypersymbolic, we make AI tools that will directly improve people's
      lives and help them
      solve their problems. We educate about AI. We harness the power of AI for
      the benefit of all.
    </p>

    <v-row
      justify="center"
      :class="$vuetify.breakpoint.xlOnly ? '' : 'flex-column'"
    >
      <v-col cols="11" xl="4">
        <v-hover
          v-slot:default="{ hover }"
        >
          <intersect @enter="$set(visibleCards, 0, true)"
                     @leave="$set(visibleCards, 0, false)">
            <a href="/#productivity" style="text-decoration: none">
              <v-card
                elevation="0"
                class="mx-auto"
                :class="{ 'on-hover': hover }"
              >
                <intersect @enter="$set(visibleLevelBelowFirstCard, 0, true)"
                           @leave="$set(visibleLevelBelowFirstCard, 0, false)">
                  <div style="transform: translateY(50vh);"></div>
                </intersect>
                <intersect @enter="$set(visibleLevelBelowFirstCard, 1, true)"
                           @leave="$set(visibleLevelBelowFirstCard, 1, false)">
                  <div style="transform: translateY(65vh);"></div>
                </intersect>
                <intersect @enter="$set(visibleLevelBelowFirstCard, 2, true)"
                           @leave="$set(visibleLevelBelowFirstCard, 2, false)">
                  <div style="transform: translateY(80vh);"></div>
                </intersect>
                <v-img
                  src="@/assets/backgrounds/productivity-square-1.jpg"
                  :aspect-ratio="$vuetify.breakpoint.xlOnly ? '1' : '3'"
                >
                  <div
                    v-if="(!isTouchDevice && hover ) ||
                          (isTouchDevice &&
                           visibleLevelBelowFirstCard[0] &&
                           visibleLevelAboveLastCard[2])"
                    class="section-card-text align-center"
                    style="height: 100%;"
                  >
                    <v-card-text
                      style="font-weight: 900; height: 100%; justify-content: center"
                      class="align-center d-flex justify-center
                         text-h4 text-sm-h3 text-xl-h3 black--text"
                    >
                      <span>Productivity</span>
                    </v-card-text>
                  </div>
                </v-img>
              </v-card>
            </a>
          </intersect>
        </v-hover>
      </v-col>
      <v-col cols="11" xl="4">
        <v-hover
          v-slot:default="{ hover }"
        >
          <intersect @enter="$set(visibleCards, 1, true)"
                     @leave="$set(visibleCards, 1, false)">
            <a href="/#agriculture-environment" style="text-decoration: none">
              <v-card
                elevation="0"
                class="mx-auto"
                :class="{ 'on-hover': hover }"
              >
                <v-img
                  src="@/assets/backgrounds/agriculture-environment-square-1.jpg"
                  :aspect-ratio="$vuetify.breakpoint.xlOnly ? '1' : '3'"
                >
                  <div
                    v-if="(!isTouchDevice && hover ) ||
                          (isTouchDevice &&
                           visibleLevelBelowFirstCard[1] &&
                           visibleLevelAboveLastCard[1])"
                    class="section-card-text align-center"
                    style="height: 100%;"
                  >
                    <v-card-text
                      style="font-weight: 900; height: 100%; justify-content: center"
                      class="align-center d-flex justify-center
                         text-h4 text-sm-h3 text-xl-h3 black--text"
                    >
                      <span>Agriculture&nbsp;&<br>Environment</span>
                    </v-card-text>
                  </div>
                </v-img>
              </v-card>
            </a>
          </intersect>
        </v-hover>
      </v-col>
      <v-col cols="11" xl="4">
        <v-hover
          v-slot:default="{ hover }"
        >
          <intersect @enter="$set(visibleCards, 3, true)"
                     @leave="$set(visibleCards, 3, false)">
            <a href="/#algorithmic-services" style="text-decoration: none">
              <v-card
                elevation="0"
                class="mx-auto"
                :class="{ 'on-hover': hover }"
              >
                <v-img
                  src="@/assets/backgrounds/algorithmic-services-square-1.jpg"
                  :aspect-ratio="$vuetify.breakpoint.xlOnly ? '1' : '3'"
                >
                  <div
                    v-if="(!isTouchDevice && hover ) ||
                          (isTouchDevice &&
                           visibleLevelBelowFirstCard[2] &&
                           visibleLevelAboveLastCard[0])"
                    class="section-card-text align-center"
                    style="height: 100%;"
                  >
                    <v-card-text
                      style="font-weight: 900; height: 100%; justify-content: center"
                      class="align-center d-flex justify-center
                         text-h4 text-sm-h3 text-xl-h3 black--text"
                    >
                      <span>Algorithmic<br>Services</span>
                    </v-card-text>
                  </div>
                </v-img>
                <intersect @enter="$set(visibleLevelAboveLastCard, 0, true)"
                           @leave="$set(visibleLevelAboveLastCard, 0, false)">
                  <div style="transform: translateY(-50vh);"></div>
                </intersect>
                <intersect @enter="$set(visibleLevelAboveLastCard, 1, true)"
                           @leave="$set(visibleLevelAboveLastCard, 1, false)">
                  <div style="transform: translateY(-65vh);"></div>
                </intersect>
                <intersect @enter="$set(visibleLevelAboveLastCard, 2, true)"
                           @leave="$set(visibleLevelAboveLastCard, 2, false)">
                  <div style="transform: translateY(-80vh);"></div>
                </intersect>
              </v-card>
            </a>
          </intersect>
        </v-hover>
      </v-col>
    </v-row>
    <div style="height: 10vh"></div>
  </div>
</template>

<script>
import Intersect from 'vue-intersect';

export default {
  name: 'Intro',

  components: {
    Intersect
  },

  data: () => ({
    visibleCards: [false, false, false],
    visibleLevelBelowFirstCard: [false, false, false],
    visibleLevelAboveLastCard: [false, false, false],
    isTouchDevice: undefined
  }),

  mounted () {
    this.isTouchDevice = (('ontouchstart' in window)
      || (navigator.maxTouchPoints > 0)
      || (navigator.msMaxTouchPoints > 0));
  }
};
</script>;

<style scoped>
.v-card {
  transition: opacity .4s ease-out;
}

.v-card:not(.on-hover) {
  opacity: 1;
}

.v-card.on-hover {
  opacity: 1;
}

.section-card-text {
  background-color: #ffffff99;
}
</style>
