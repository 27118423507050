<template>
  <div class="product-card">
    <v-row justify="center">
      <v-col cols="12" xs="12" sm="11" md="11">
        <v-card
          color="white"
          min-width="200"
        >
          <v-row no-gutters class="d-flex flex-no-wrap justify-center align-center">
            <v-col class="shrink">
              <v-avatar
                class="ma-3"
                size="125"
                tile
              >
                <v-img :src="getImagePath(img)"></v-img>
              </v-avatar>
            </v-col>
            <v-col style="min-width: 300px">
              <v-card-title class="headline text" style="font-weight: bold">
                <slot name="title"></slot>
              </v-card-title>
              <v-card-subtitle class="text-h6">
                <slot name="subtitle"></slot>
              </v-card-subtitle>
              <v-card-text class="text-body-1">
                <slot></slot>
              </v-card-text>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'ProductCard',

  props: {
    img: {
      type: String
    },
  },

  methods: {
    getImagePath (src) {
      const images = require.context(
        '@/assets/',
        false,
        /\.jpg$|\.png$|\.jpeg$|\.svg$|\.tiff$|\.gif$/
      );
      return images(`./${src}`);
    }
  }
};
</script>

<style scoped>
.product-card {
  max-width: 1080px;
}
</style>
