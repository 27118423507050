<template>
  <div class="about">
    <v-container class="d-flex justify-center">
      <v-col cols="11" xs="11" sm="10" md="10" lg="9" xl="9" class="grow">
        <p>
          Hypersymbolic provides AI services to augment human intelligence.
        </p>
        <h1>What we do</h1>
        <v-row justify="center" align="top" class="pt-4">
          <v-col cols="6" xs="6" sm="6" md="6" lg="4" xl="3">
            <about-card img="about-icons-nlp-web.svg">
              <template v-slot:title>
                Natural Language Processing
              </template>
              Natural Language Processing is central to our products and education initiatives
              simply because most human knowledge is linguistic. Therefore, we strive to remain
              ahead of the industry, exploring the state of the art NLP techniques to make our
              services and products as useful to people as the latest research allows. That
              emphasis is complemented by the use of IA algorithms for image analysis. Indeed,
              we have from the start taken a holistic stance towards AI, and we actively pursue
              information fusion and NLP model grounding through multi-media contextualization
              and, ultimately, through model embodiement.
            </about-card>
          </v-col>
          <v-col cols="6" xs="6" sm="6" md="6" lg="4" xl="3">
            <about-card img="about-icons-geospatial-web.svg">
              <template v-slot:title>
                Geospatial Intelligence
              </template>
              <div>
                Space and territory constitute a dimension of human activity where AI has a
                great potential to help take better decisions and better manage risk. These
                benefits of statistical geospatial models are harnessed in some sectors such as
                the mining industry, but they are seldom made available to smaller players.
                City planning, ecological conservation, sustainable agricultural practice, and
                even the decisions that every family has to make regarding where to live should
                also be able to benefit from those tools.
              </div>
              <div>
                At Hypersymbolic, we harness our expertise with Geospatial Intelligence tools
                and related AI models to transform geospatial data and model predictions into
                accessible information laid out in interactive visualisations to aid decision
                making.
              </div>
            </about-card>
          </v-col>
          <v-col cols="6" xs="6" sm="6" md="6" lg="4" xl="3">
            <about-card img="about-icons-regression-web.svg">
              <template v-slot:title>
                Explainable AI Models
              </template>
              Deep Learning based AI models often offer superior results at the costs of lower
              explainability and, relatedly, of being harder to intuitively understand.
              Our modeling work aims at explainable models with clear uncertainty estimates.
              It is a crucial element in our vision of building AI that merits to be trusted by
              people: each system must be able to explain its decision in terms that are
              understandable to its users.
            </about-card>
          </v-col>
          <v-col cols="6" xs="6" sm="6" md="6" lg="4" xl="3">
            <about-card img="about-icons-ml-eng-web.svg">
              <template v-slot:title>
                Machine Learning & Data Engineering
              </template>
              We have extensive experience in implementing Machine Learning techniques and
              their supporting ETL pipeline. We believe that engineering
              quality should never be compromised when serving models supporting
              decision-making. Our Data Science, Artificial Intelligence and Machine Learning
              engineering and modeling codebase is carefully reviewed for correctness and
              reliability, while our backend services and frontend interfaces are designed to
              protect and isolate users' data and mitigate the intrusion risk.
            </about-card>
          </v-col>
        </v-row>
      </v-col>

      <div class="pt-10"></div>
    </v-container>

    <v-container class="pt-10 d-flex justify-center">
      <v-col cols="11" xs="11" sm="10" md="10" lg="9" xl="9" class="grow">
        <h1>Who we are</h1>
        <v-row justify="space-between" align="top" class="">
          <v-col cols="6" xs="6" sm="6" md="6" lg="4" xl="3">
            <about-card img="about-icons-expertise-web.svg" minHeight=250 popupWidth=1000>
              <template v-slot:title>
                Our Expertise
              </template>
              We are a small, close-knit team of experienced product developers and researchers
              in AI and Economics. We have both industry and academic experience in Data Science,
              Artificial Intelligence, Statistics, Software Engineering, and know how to create
              useful mathematical models to solve the hard optimization problems faced by people,
              companies and countries.
              <h2 class="mt-4">The founding team</h2>
              <v-container fluid>
                <v-row>
                  <v-col cols="12" md="6">
                    <v-card>
                      <v-row
                        justify="center"
                      >
                        <v-col class="d-flex justify-center align-center">
                          <v-img src="@/assets/about-images-founders-profiles-alexandre.webp"
                                 :max-width="$vuetify.breakpoint.xsOnly ? 300 : 400"
                                 alt="Profile picture of Alexandre Gravier">
                          </v-img>
                        </v-col>
                        <v-col class="mx-2 d-flex
                                        flex-column flex-shrink-1 align-center justify-center">
                          <v-card-title>
                            <span class="text-no-wrap">Alexandre Gravier</span>
                          </v-card-title>
                          <v-card-subtitle>
                            Strategy, AI and Tech
                          </v-card-subtitle>
                        </v-col>
                      </v-row>
                      <v-card-text>
                        Alexandre's Artificial Intelligence, Data Science and Software Engineering
                        skills were honed both in Academia and in the tech industry. He obtained
                        his MSc in AI from Paul Sabatier University (Toulouse, France) and his PhD
                        in Computational Neuroscience from Nanyang Technological University
                        (Singapore), where he did research at the Centre for Computational
                        Intelligence and the Robotics Research Centre. He worked at Google, NTU,
                        Teralytics, RedMart and SEEK before founding Hypersymbolic. He has built new
                        startup teams and worked in large companies. He is passionate and
                        opiniated about the development of trustable, beneficial AI that helps
                        people.
                      </v-card-text>
                    </v-card>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-card>
                      <v-row>
                        <v-col class="d-flex justify-center align-center">
                          <v-img src="@/assets/about-images-founders-profiles-joanna.webp"
                                 :max-width="$vuetify.breakpoint.xsOnly ? 300 : 400"
                                 alt="Profile picture of Joanna Gravier-Rymaszewska">
                          </v-img>
                        </v-col>
                        <v-col class="mx-2 d-flex
                                        flex-column flex-shrink-1 align-center justify-center">
                          <v-card-title>
                            <span class="">Joanna Gravier-Rymaszewska</span>
                          </v-card-title>
                          <v-card-subtitle>
                            Statistics, Finance and Design
                          </v-card-subtitle>
                        </v-col>
                      </v-row>
                      <v-card-text>
                        Joanna has a PhD in Economics specializing in Mathematical Modelling from
                        Nanyang Technological University (Singapore) and a MSc in Economics from the
                        University of Warsaw (Poland), a B.Sc.Eng in Information Management Systems
                        from Warsaw University of Technology (Poland). She was a Rotary
                        International Business Scholar in the US, working with creators of American
                        SME. She has done research, developed models and taught for Nanyang
                        Technological University, and worked at the United Nations (UNU-WIDER
                        Fellow, Helsinki, Finland). She was an advisor to the Governor of the
                        Central Bank of Malta (ESCB) before becoming a private financial investor
                        and entrepreneur.
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </v-container>
            </about-card>
          </v-col>
          <v-col cols="6" xs="6" sm="6" md="6" lg="4" xl="3">
            <about-card img="about-icons-mission-web.svg" minHeight=250>
              <template v-slot:title>
                Our Mission
              </template>
              <div>
                We create AI services to help communities work and live together, and to help
                individuals through their day. We believe that every person should have access to
                the powerful automation, analysis and decision making tools that the Computer
                Science and Mathematics communities have created (often with public funding), but
                which are often used by large organisations but not directly accessible to
                individuals.
              </div>
              <div>
                As experts in AI, we also consider it our duty towards the community to share our
                knowledge. We therefore make it a mission to provide educational materials to
                demystify AI. We do so by creating online interactive visualization of the
                fundamental algorithms behind modern AI systems.
              </div>
            </about-card>
          </v-col>
          <v-col cols="6" xs="6" sm="6" md="6" lg="4" xl="3">
            <about-card img="about-icons-story-web.svg" minHeight=250>
              <template v-slot:title>
                Our Story
              </template>
              Hypersymbolic is a self-funded family business. Joanna and I (Alexandre) met during
              our university exchange program in Singapore, and our interests broadened and
              converged towards AI, economics, health and global issues. A few years later, having
              left academia, we felt that salaried employment was limiting in more than one way and
              had many venture ideas. Hypersymbolic was formed to bring our AI-related initiatives
              under the same roof, following a coherent product line.
            </about-card>
          </v-col>
        </v-row>
      </v-col>
    </v-container>
  </div>
</template>

<script>
import AboutCard from '@/components/AboutCard.vue';
import '@/particles';

export default {
  name: 'about',

  components: {
    AboutCard,
  },

  methods: {
    initParticles() {
      window.particlesJS('particles-js', {
        particles: {
          number: { value: 30, max_particles: 30, density: { enable: false, value_area: 800 } },
          color: { value: '#bb52af' },
          shape: {
            type: 'circle',
            stroke: { width: 1, color: '#2a2a2a' },
            polygon: { nb_sides: 5 },
          },
          opacity: {
            value: 0.5,
            random: false,
            anim: {
              enable: false, speed: 1, opacity_min: 0.1, sync: false
            }
          },
          size: {
            value: 3,
            random: true,
            anim: {
              enable: false, speed: 40, size_min: 0.1, sync: false
            }
          },
          line_linked: {
            enable: true,
            distance: 500,
            color: '#a546cd',
            opacity: 0.4,
            width: 1
          },
          move: {
            enable: true,
            speed: 3.0,
            direction: 'none',
            random: false,
            straight: false,
            out_mode: 'bounce',
            bounce: false,
            attract: { enable: false, rotateX: 600, rotateY: 1200 }
          }
        },
        interactivity: {
          detect_on: 'window',
          events: {
            onhover: { enable: false, mode: 'repulse' }, // grab, bubble, repulse, push, remove
            onclick: { enable: true, mode: 'push' },
            resize: true
          },
          modes: {
            grab: { distance: 100, line_linked: { opacity: 0.5 } },
            bubble: {
              distance: 50, size: 10, duration: 2, opacity: 8, speed: 3
            },
            repulse: { distance: 40, duration: 0.2 },
            push: { particles_nb: 3 },
            remove: { particles_nb: 2 }
          }
        },
        retina_detect: true
      });
    },
  },

  mounted() {
    this.initParticles();
  },
};
</script>

<style scoped>

</style>
