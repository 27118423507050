<template>
  <v-app-bar
    :width=width
    flat
    :fixed=fixed
    dense
    :color="barColor"
    class="px-0 mb-4"
    :class="[{centered: fixed}]"
  >
    <v-row justify="center" no-gutters>

      <v-col
        align-self="center"
      >
        <div style="width: 50px" class="pl-2 pr-1 ml-2">
          <a
            v-if="!noLogo"
            href="/#top"
          >
          <v-img
              src="@/assets/logo-web.svg"
              alt="Hypersymbolic logo"
              height="44px"
              contain
            ></v-img>
          </a>
          <span class="kinda-empty" v-else></span>
        </div>
      </v-col>

      <v-col
        align-self="center"
        cols="11" xs="11" sm="9" md="7" lg="6" xl="6" v-if="!compactDisplay"
      >
        <v-row
          no-gutters
          align="center"
          align-content="stretch"
          justify="center"
          class="d-flex justify-space-around flex-nowrap"
        >
          <v-btn
            elevation="0"
            height="48px"
            v-bind="buttonSize"
            class="navbar-button"
            :color="activeButton === hsNavBarButtons[0] ? productivityColor : 'transparent'"
            :ref="hsNavBarButtons[0]"
            href="/#productivity"
          >
            Productivity
          </v-btn>
          <v-btn
            elevation="0"
            height="48px"
            v-bind="buttonSize"
            class="navbar-button"
            :color="activeButton === hsNavBarButtons[1]?agricultureEnvironmentColor : 'transparent'"
            :ref="hsNavBarButtons[1]"
            href="/#agriculture-environment"
          >
            Agriculture & Environment
          </v-btn>
          <v-btn
            elevation="0"
            height="48px"
            v-bind="buttonSize"
            class="navbar-button"
            :color="activeButton === hsNavBarButtons[2] ? algorithmicServicesColor : 'transparent'"
            :ref="hsNavBarButtons[2]"
            href="/#algorithmic-services"
          >
            Algorithmic Services
          </v-btn>
        </v-row>
      </v-col>

      <v-col
        align-self="center"
      >
        <v-row
          no-gutters
          align="center"
          align-content="center"
          justify="center"
        >
          <v-col
            col="11"
            v-if="$vuetify.breakpoint.lgAndUp"
          >
            <v-row
              no-gutters
              align="center"
              align-content="center"
              justify="center"
              class="d-flex justify-space-around flex-nowrap"
            >
              <v-btn
                outlined
                v-bind="buttonSize"
                class="right-navbar-button"
                :style="activeButton === hsNavBarButtons[3] ? {backgroundColor: aboutColor} : {}"
                :ref="hsNavBarButtons[3]"
                href="/#about"
              >
                About
              </v-btn>
              <v-btn
                outlined
                v-bind="buttonSize"
                class="right-navbar-button"
                :style="activeButton === hsNavBarButtons[4] ? {backgroundColor: contactColor} : {}"
                :ref="hsNavBarButtons[4]"
                href="/#contact"
              >
                <v-icon left>mdi-pencil</v-icon>
                Contact
              </v-btn>
            </v-row>
          </v-col>
          <v-col lg="1" class="right-menu-container justify-end mr-2">
            <div style="width: 50px" class="pl-0 pr-0 py-1">
              <div v-if="compactDisplay">
                <v-menu
                  bottom
                  left
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-app-bar-nav-icon></v-app-bar-nav-icon>
                    </v-btn>
                  </template>

                  <v-list>
                    <v-list-item>
                      <v-list-item-title>
                        <v-btn
                          elevation="0"
                          height="48px"
                          class="navbar-button"
                          :color="activeButton === hsNavBarButtons[0] ?
                                  productivityColor : 'transparent'"
                          :ref="hsNavBarButtons[0]"
                          href="/#productivity"
                        >
                          Productivity
                        </v-btn>
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-title>
                        <v-btn
                          elevation="0"
                          height="48px"
                          class="navbar-button"
                          :color="activeButton === hsNavBarButtons[1] ?
                                  agricultureEnvironmentColor : 'transparent'"
                          :ref="hsNavBarButtons[1]"
                          href="/#agriculture-environment"
                        >
                          Agriculture & Environment
                        </v-btn>
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-title>
                        <v-btn
                          elevation="0"
                          height="48px"
                          class="navbar-button"
                          :color="activeButton === hsNavBarButtons[2] ?
                                  algorithmicServicesColor : 'transparent'"
                          :ref="hsNavBarButtons[2]"
                          href="/#algorithmic-services"
                        >
                          Algorithmic Services
                        </v-btn>
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-title>
                        <v-btn
                          outlined
                          class="right-navbar-button"
                          :style="activeButton === hsNavBarButtons[3] ?
                                  {backgroundColor: aboutColor} : {}"
                          :ref="hsNavBarButtons[3]"
                          href="/#about"
                        >
                          About
                        </v-btn>
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-title>
                        <v-btn
                          outlined
                          class="right-navbar-button"
                          :style="activeButton === hsNavBarButtons[4] ?
                                  {backgroundColor: contactColor} : {}"
                          :ref="hsNavBarButtons[4]"
                          href="/#contact"
                        >
                          <v-icon left>mdi-pencil</v-icon>
                          Contact
                        </v-btn>
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
              <div v-else-if="!$vuetify.breakpoint.lgAndUp">
                <v-menu
                  bottom
                  left
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-app-bar-nav-icon></v-app-bar-nav-icon>
                    </v-btn>
                  </template>

                  <v-list>
                    <v-list-item>
                      <v-list-item-title>
                        <v-btn
                          outlined
                          class="right-navbar-button"
                          :color="activeButton === hsNavBarButtons[3] ? aboutColor : ''"
                          :ref="hsNavBarButtons[3]"
                          href="/#about"
                        >
                          About
                        </v-btn>
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-title>
                        <v-btn
                          outlined
                          class="right-navbar-button"
                          :color="activeButton === hsNavBarButtons[4] ? contactColor : ''"
                          :ref="hsNavBarButtons[4]"
                          href="/#contact"
                        >
                          <v-icon left>mdi-pencil</v-icon>
                          Contact
                        </v-btn>
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
              <span class="kinda-empty"></span>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-app-bar>
</template>

<script>

export default {
  name: 'HypersymbolicNavBar',

  data() {
    return {
      hsNavBarButtons: [
        'productivity',
        'agriculture-environment',
        'algorithmic-services',
        'about',
        'contact',
      ],
      productivityColor: '#E3C678',
      agricultureEnvironmentColor: '#6EBC7C',
      algorithmicServicesColor: '#7FB9E5',
      aboutColor: '#D7C6D7',
      contactColor: '#D7C6D7',
    };
  },

  props: {
    width: {
      type: String,
      default: '100%',
    },
    fixed: {
      type: Boolean,
      default: false,
    },
    noLogo: {
      type: Boolean,
      default: false,
    },
    activeButton: {
      type: String,
      default: undefined,
    },
    barColor: {
      type: String,
      default: '#fceef7ee',
    },
  },

  computed: {
    compactDisplay() {
      return this.$vuetify.breakpoint.xs;
    },
    buttonSize() {
      const size = {
        xs: 'x-small', sm: 'x-small', md: 'small', lg: 'small', xl: 'small'
      }[this.$vuetify.breakpoint.name];
      return size ? { [size]: true } : {};
    },
  }
};
</script>

<style scoped>
.centered {
  margin-left: auto;
  margin-right: auto;
}

.kinda-empty {
  content: "\A0";
}

.right-menu-container {
  display: flex;
  justify-content: right;
}

/deep/ .v-toolbar__content {
  padding: 0 !important;
}

.active-central-menu-btn {
  background-color: transparent !important;
}

.navbar-button {
  font-weight: 900;
  text-align: center;
}

.right-navbar-button {
  font-weight: 900;
  text-align: center;
  color: rgba(0, 0, 0, 0.87);
}

</style>
