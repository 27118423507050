<template>
  <div
    style="display: grid; margin-bottom: 5vh"
  >
    <v-row
      no-gutters
      dense
      justify="center"
      style="grid-column: 1; grid-row: 1;"
    >
      <v-col cols="11" xs="11" sm="9" md="7" lg="6" xl="6">
        <h2 :style="{'color': dark ? 'white' : 'black'}">
          <span
            class="px-3"
            :style="{'background-color': color, 'line-height': size+'px', 'font-size': size+'px'}"
          >
            <slot></slot>
          </span>
        </h2>
      </v-col>
    </v-row>
    <v-row
      no-gutters
      dense
      style="grid-column: 1; grid-row: 2; height: 3px"
    >
      <v-col
        cols="12"
        style="height: 5px"
      >
        <div
          :style="{'background-color': color, height: '100%'}"
        >
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'SectionTitle',
  props: {
    color: {
      type: String,
      default: '#FF4444',
    },
    dark: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    size() {
      const size = {
        xs: 30, sm: 32, md: 32, lg: 36, xl: 40
      }[this.$vuetify.breakpoint.name];
      return size || 0;
    },
  },

};
</script>

<style scoped>
h2 {
  padding-left: 0.5em;
  padding-right: 0.5em;
}

div:empty::before {
  content: "\A0";
}
</style>
