<template>
  <v-card
    class="mx-auto my-auto pa-0"
    :style="{width: 256 * scale + 'px', background: 'transparent'}"
    flat
  >
    <v-img
      src="@/assets/logo-web.svg"
      alt="Hypersymbolic logo"
      :style="{width: 256 * scale + 'px'}"
    ></v-img>

    <v-card-title
      class="pa-0 company-name"
      :style="{'justify-content': 'center', 'padding-top': scale * 3 + 'px'}"
    >
      <span
        class="text-no-wrap"
        :style="{'font-size': scale * 32 + 'px', 'letter-spacing': scale * 2.3 + 'px'}"
      >
        Hypersymbolic
      </span>
    </v-card-title>
  </v-card>

</template>

<script>
export default {
  name: 'LogoWithName',

  props: {
    scale: {
      type: Number,
      default: 1.0,
    },
  },

  computed: {
    theme() {
      return (this.$vuetify.theme.dark) ? 'dark' : 'light';
    }
  }
};
</script>

<style scoped>
.company-name {
  font-weight: 700;
  color: var(--v-secondary-darken3);
}
</style>
