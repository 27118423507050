<template>
  <div class="contact">
    <div class="pt-10">
      <v-row justify="center" no-gutters class="mb-8">
        <v-col cols="11" xs="11" sm="9" md="7" lg="6" xl="6"
               class="d-flex justify-center">
          <h3>Have any questions? We’d love to hear from you.</h3>
        </v-col>
      </v-row>
      <v-row justify="center" no-gutters>
        <v-col cols="11" xs="11" sm="9" md="7" lg="6" xl="6"
               class="d-flex justify-center">
          <v-card
            outlined
            class="pa-xs-1 pa-sm-3 pa-md-8 pa-lg-12 pa-xl-16 grow"
            :class="$vuetify.breakpoint.smAndUp ? 'form-card' : 'form-card-mobile'"
          >
            <validation-observer
              ref="observer"
              v-slot=""
            >
              <form
                name="contact"
                method="post"
                data-netlify="true"
                data-netlify-honeypot="bot-field"
              >
                <input type="hidden" name="form-name" value="contact" />
                <validation-provider
                  v-slot="{ errors }"
                  name="Name"
                  rules="required|max:100"
                >
                  <v-text-field
                    name="name"
                    outlined
                    v-model="name"
                    :counter="100"
                    :error-messages="errors"
                    label="Your name"
                    required
                  ></v-text-field>
                </validation-provider>
                <validation-provider
                  v-slot="{ errors }"
                  name="email"
                  rules="required|email"
                >
                  <v-text-field
                    name="email"
                    outlined
                    v-model="email"
                    :error-messages="errors"
                    label="Your e-mail address"
                    required
                  ></v-text-field>
                </validation-provider>
                <validation-provider
                  v-slot="{ errors }"
                  name="subject"
                  rules="required|max:1000"
                >
                  <v-text-field
                    name="subject"
                    outlined
                    v-model="subject"
                    :error-messages="errors"
                    :counter="1000"
                    label="Subject"
                    required
                  ></v-text-field>
                </validation-provider>

                <validation-provider
                  v-slot="{ errors }"
                  name="message"
                  rules="required|max:10000"
                >
                  <v-textarea
                    name="message"
                    outlined
                    v-model="message"
                    :counter="10000"
                    :error-messages="errors"
                    label="Message"
                    required
                  ></v-textarea>
                </validation-provider>
                <v-row justify="center">
                  <v-col cols="11" xs="11" sm="3" md="3" lg="3" xl="3">
                    <v-btn
                      color="primary"
                      block
                      elevation="0"
                      @click="submit"
                      :disabled="!readyToSubmit"
                    >
                      submit
                    </v-btn>
                  </v-col>
                  <v-col cols="11" xs="11" sm="3" md="3" lg="3" xl="3">
                    <v-btn
                      color="secondary"
                      outlined
                      block
                      @click="clear"
                    >
                      clear
                    </v-btn>
                  </v-col>
                </v-row>
              </form>
            </validation-observer>
          </v-card>
        </v-col>
      </v-row>
    </div>
    <div v-if="$vuetify.breakpoint.smAndDown" style="height: 20vh"></div>
  </div>
</template>

<script>
import axios from 'axios';
import '@/particles';
import {
  extend, ValidationObserver, ValidationProvider, setInteractionMode
} from 'vee-validate';
import { required, email, max } from 'vee-validate/dist/rules';

setInteractionMode('eager');

extend('required', {
  ...required,
  message: '{_field_} should not be empty',
});

extend('max', {
  ...max,
  message: '{_field_} may not be greater than {length} characters',
});

extend('email', {
  ...email,
  message: 'E-mail must be valid',
});

export default {
  name: 'contact',

  components: {
    ValidationProvider,
    ValidationObserver,
  },

  data: () => ({
    name: '',
    email: '',
    subject: '',
    message: '',
    errors: null,
  }),

  computed: {
    formData() {
      return {
        name: this.name,
        email: this.email,
        subject: this.subject,
        message: this.message
      };
    },
    isCompleted() {
      return this.name && this.email && this.subject && this.message;
    },
    readyToSubmit() {
      const anyError = this.errors !== null && this.errors.any();
      return this.isCompleted && !anyError;
    }
  },

  methods: {
    initParticles() {
      window.particlesJS('particles-js', {
        particles: {
          number: { value: 30, max_particles: 30, density: { enable: false, value_area: 800 } },
          color: { value: '#bb52af' },
          shape: {
            type: 'circle',
            stroke: { width: 1, color: '#2a2a2a' },
            polygon: { nb_sides: 5 },
          },
          opacity: {
            value: 0.5,
            random: false,
            anim: {
              enable: false, speed: 1, opacity_min: 0.1, sync: false
            }
          },
          size: {
            value: 3,
            random: true,
            anim: {
              enable: false, speed: 40, size_min: 0.1, sync: false
            }
          },
          line_linked: {
            enable: true,
            distance: 500,
            color: '#a546cd',
            opacity: 0.4,
            width: 1
          },
          move: {
            enable: true,
            speed: 3.0,
            direction: 'none',
            random: false,
            straight: false,
            out_mode: 'bounce',
            bounce: false,
            attract: { enable: false, rotateX: 600, rotateY: 1200 }
          }
        },
        interactivity: {
          detect_on: 'window',
          events: {
            onhover: { enable: false, mode: 'repulse' }, // grab, bubble, repulse, push, remove
            onclick: { enable: true, mode: 'push' },
            resize: true
          },
          modes: {
            grab: { distance: 100, line_linked: { opacity: 0.5 } },
            bubble: {
              distance: 50, size: 10, duration: 2, opacity: 8, speed: 3
            },
            repulse: { distance: 40, duration: 0.2 },
            push: { particles_nb: 3 },
            remove: { particles_nb: 2 }
          }
        },
        retina_detect: true
      });
    },
    encode (data) {
      return Object.keys(data)
        .map(
          (key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`
        )
        .join('&');
    },
    submit() {
      const axiosConfig = {
        header: { 'Content-Type': 'application/x-www-form-urlencoded' }
      };
      axios.post(
        '/',
        this.encode({
          'form-name': 'contact',
          ...this.formData
        }),
        axiosConfig
      ).then(() => {
        this.$router.push('/contact_success');
      }).catch(() => {
        this.$router.push('/contact_failure');
      });
    },
    clear() {
      this.name = '';
      this.email = '';
      this.subject = '';
      this.message = '';
      this.$refs.observer.reset();
    },
  },

  mounted() {
    this.initParticles();
  },
};
</script>

<style scoped>

.form-card {
  max-width: 1080px;
  border-width: 3px;
  background-color: #ffffffaa;
}

.form-card-mobile {
  max-width: 1080px;
  border-width: 0;
  background-color: #ffffffaa;
}

</style>
