<template>
  <div class="algorithmic-services">
    <p>
      In the process of developing products making use of AI to help people in specific domains, we
      develop AI algorithms that may be more generally applicable as building blocks in other
      areas. We will offer them for other organisations through a Software as a Service (SAAS)
      marketplace.
    </p>
    <p>
      In view of this, we plan on designing our internal product infrastructure with AI algorithms
      modularized as authenticated services, allowing for a seamless transition to a SAAS offering.
      The cornerstones of Notr and Lands Labs are a variety of Signal Processing, Natural Language
      Processing, Statistical Analysis and Geospatial Intelligence algorithms that could be of
      interest to other companies.
    </p>
  </div>
</template>

<script>
export default {
  name: 'AlgorithmicServices',
};
</script>
