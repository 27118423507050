<template>
  <v-app>
    <router-view/>
    <div style="height: 20vh"></div>
    <footer-bar></footer-bar>
  </v-app>
</template>

<script>
import FooterBar from '@/components/FooterBar.vue';

export default {
  name: 'app',

  components: {
    FooterBar
  },

  data: () => ({
    doNotTrack: false,
  }),

  created() {
    // Record the user's DNT preference
    this.doNotTrack = (
      (window.doNotTrack && window.doNotTrack === '1')
      || (navigator.doNotTrack && (navigator.doNotTrack === 'yes' || navigator.doNotTrack === '1'))
      || (navigator.msDoNotTrack && navigator.msDoNotTrack === '1')
      || (('msTrackingProtectionEnabled' in window.external) && window.external.msTrackingProtectionEnabled())
    );
  },
};
</script>

<style>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  color: #2c3e50;
  margin-left: auto;
  margin-right: auto;
}
</style>
