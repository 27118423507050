import Vue from 'vue';
import VueSocial from '@growthbunker/vuesocial';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import router from './router';

Vue.config.productionTip = false;
Vue.use(VueSocial, {
  // Specify the path of the folder where the logos are stored.
  iconPath: '/social-networks/',
  // Specify the theme to use: dark or light (dark by default).
  theme: 'dark',
});

const root = new Vue({
  vuetify,
  router,
  render: (h) => h(App),
});

document.addEventListener('DOMContentLoaded', () => {
  root.$mount('#app');
});
