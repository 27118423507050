var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"contact"},[_c('div',{staticClass:"pt-10"},[_c('v-row',{staticClass:"mb-8",attrs:{"justify":"center","no-gutters":""}},[_c('v-col',{staticClass:"d-flex justify-center",attrs:{"cols":"11","xs":"11","sm":"9","md":"7","lg":"6","xl":"6"}},[_c('h3',[_vm._v("Have any questions? We’d love to hear from you.")])])],1),_c('v-row',{attrs:{"justify":"center","no-gutters":""}},[_c('v-col',{staticClass:"d-flex justify-center",attrs:{"cols":"11","xs":"11","sm":"9","md":"7","lg":"6","xl":"6"}},[_c('v-card',{staticClass:"pa-xs-1 pa-sm-3 pa-md-8 pa-lg-12 pa-xl-16 grow",class:_vm.$vuetify.breakpoint.smAndUp ? 'form-card' : 'form-card-mobile',attrs:{"outlined":""}},[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('form',{attrs:{"name":"contact","method":"post","data-netlify":"true","data-netlify-honeypot":"bot-field"}},[_c('input',{attrs:{"type":"hidden","name":"form-name","value":"contact"}}),_c('validation-provider',{attrs:{"name":"Name","rules":"required|max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"name":"name","outlined":"","counter":100,"error-messages":errors,"label":"Your name","required":""},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})]}}])}),_c('validation-provider',{attrs:{"name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"name":"email","outlined":"","error-messages":errors,"label":"Your e-mail address","required":""},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})]}}])}),_c('validation-provider',{attrs:{"name":"subject","rules":"required|max:1000"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"name":"subject","outlined":"","error-messages":errors,"counter":1000,"label":"Subject","required":""},model:{value:(_vm.subject),callback:function ($$v) {_vm.subject=$$v},expression:"subject"}})]}}])}),_c('validation-provider',{attrs:{"name":"message","rules":"required|max:10000"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"name":"message","outlined":"","counter":10000,"error-messages":errors,"label":"Message","required":""},model:{value:(_vm.message),callback:function ($$v) {_vm.message=$$v},expression:"message"}})]}}])}),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"11","xs":"11","sm":"3","md":"3","lg":"3","xl":"3"}},[_c('v-btn',{attrs:{"color":"primary","block":"","elevation":"0","disabled":!_vm.readyToSubmit},on:{"click":_vm.submit}},[_vm._v("\n                    submit\n                  ")])],1),_c('v-col',{attrs:{"cols":"11","xs":"11","sm":"3","md":"3","lg":"3","xl":"3"}},[_c('v-btn',{attrs:{"color":"secondary","outlined":"","block":""},on:{"click":_vm.clear}},[_vm._v("\n                    clear\n                  ")])],1)],1)],1)]},proxy:true}])})],1)],1)],1)],1),(_vm.$vuetify.breakpoint.smAndDown)?_c('div',{staticStyle:{"height":"20vh"}}):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }