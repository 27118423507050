<template>
  <v-footer
    absolute
    padless
    app
    dark
    class="text-center ma-auto pa-auto"
  >
    <v-row
      justify="center"
    >
      <v-col cols="11" xs="11" sm="11" md="4">
        <v-btn outlined small class="text mx-2" href="/#about">
          About
        </v-btn>
        <v-btn outlined small class="text mx-2" href="/#contact">
          Contact
        </v-btn>
        <v-btn outlined small class="text mx-2" href="/legal">
          Legal
        </v-btn>
      </v-col>
      <v-col :class="$vuetify.breakpoint.mdAndUp ? 'shrink' : ''">
        <span class="mx-2 text-no-wrap"
        >
           &copy; {{ new Date().getFullYear() }} — <strong>Hypersymbolic</strong>
        </span>
      </v-col>
      <v-col cols="11" xs="11" sm="11" md="4">
        Follow us:
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.linkedin.com/company/hypersymbolic/"
        >
          <gb-social-button
            class="mx-2 pa-1"
            network="linkedin"
            reverse
            size="large"
            iconTheme="white"
            theme="dark"
          >
          </gb-social-button>
        </a>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.facebook.com/Hypersymbolic-106481101234502"
        >
          <gb-social-button
            class="mx-2 pa-1"
            network="facebook"
            reverse
            size="large"
            iconTheme="white"
            theme="dark"
          >
          </gb-social-button>
        </a>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://twitter.com/hypersymbolic"
        >
          <gb-social-button
            class="mx-2 pa-1"
            network="twitter"
            reverse
            size="large"
            iconTheme="white"
            theme="dark"
          >
          </gb-social-button>
        </a>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.instagram.com/hypersymbolic/"
        >
          <gb-social-button
            class="mx-2 pa-1"
            network="instagram"
            reverse
            size="large"
            iconTheme="white"
            theme="dark"
          >
          </gb-social-button>
        </a>
      </v-col>
    </v-row>
  </v-footer>
</template>

<script>
export default {
  name: 'Footer',
  data: () => ({})
};
</script>

<style scoped>

</style>
