<template>
  <div class="productivity">
    <p>
      We develop products that harness our deep expertise in AI to help people in their day-to-day
      work and struggles. Our first product, Notr, is a personal knowledge assistant.
    </p>
    <product-card img="logo-notr-web.svg">
      <template v-slot:title>
        Notr
      </template>
      <template v-slot:subtitle>
        AI for knowledge management
      </template>
      Notr is immediately useful to help take notes, but its true power lies in its <strong>holistic
      knowledge management</strong>. First, Notr goes beyond live captioning by summarizing
      information and extracting decisions. Second, the Notr expert system <strong>contextualizes
      all documents in a knowledge graph</strong>, benefiting from sporadic human signal. This
      context allows the unsupervised construction of powerful live documents that relate all
      relevant information.
    </product-card>
    <p>
      We make our consumer products affordable to those who need it the most. In line with our
      mission to make AI that benefits people, Notr's power will not only be made available to note
      takers and knowledge workers at businesses, but also to students and
      educators, as well as to the general public.
    </p>
  </div>
</template>

<script>
import ProductCard from '@/components/ProductCard.vue';

export default {
  name: 'Productivity',

  components: {
    ProductCard,
  }
};
</script>
