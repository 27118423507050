<template>
  <div class="agriculture-environment">
    <p>
      We believe that there is value in <strong>an online platform to guide on-farm
      experimentation</strong>.
    </p>
    <p>
      Small, independent farmers adapt their agricultural know-how to to changing circumstances.
      They will often build up local knowledge by using local experiments. This practice has a great
      potential for innovation. Hypersymbolic will develop a platform and community to help
      document, monitor and evaluate on-farm experimentation strategies. While the online community
      will let farmers share the results and cooperate, the unique value of our platform lies in the
      tools to help with the knowledge management and statistical analysis of the experimental
      results. It is more difficult for a small organic farmer to experiment due to the small sample
      sizes and the susceptibility of their crops and livestock experiments to more spurious
      interactions and confounding factors.
    </p>

    <product-card img="logo-agrienv-web.svg">
      <template v-slot:title>
        Lands Labs
      </template>
      <template v-slot:subtitle>
        Experiment management and statistical tools for farming and environment
      </template>
      Lands Labs offers a platform to manage on-farm experiments, including help with data
      collection and annotation and assistance to interpret the statistical significance of the
      results and determine if an experiment is conclusive.
    </product-card>
    <p>
      Therefore, our on-farm experimentation platform will be designed with organic and small-scale
      farming in mind. It will let the farmer create experiments, express hypotheses, register
      measurements, and will inform the farmer of the result of the experiment with an estimate of
      uncertainty. Guided in their use of the scientific method, farmers will be able to increase
      yield, manage soil fertility and gain and share a better understanding of the local factors.
    </p>
    <p>
      In later iterations, we will be able to integrate shared experiments into specialized models
      of soil fertility, plant growth and crop yield. These expert models accounting for all of the
      data collected, will make use of geospatial intelligence.
    </p>
    <v-row justify="center">
      <v-col cols="11" xs="10" sm="8" md="8" lg="7" class="my-6"><hr></v-col>
    </v-row>
    <p>
      Geospatial intelligence models have a wider applicability to <strong>risk management for
      natural and man-made disasters</strong>. Hypersymbolic plans on exploring this domain, once a
      small group of users has been identified for a pilot project. The initial scope could be that
      of environmental risk management, or potentially expand to predicting climate-related
      catastrophe risk.
    </p>
  </div>
</template>

<script>
import ProductCard from '@/components/ProductCard.vue';

export default {
  name: 'AgricultureEnvironment',

  components: {
    ProductCard,
  }
};
</script>
