<template>
  <v-card
    outlined
    class="about-card d-flex justify-space-around flex-nowrap flex-column"
    :style="{'min-height': minHeight+'px'}"
  >
    <div
      class="d-flex justify-center mt-xs-0 mx-xs-0 mt-sm-14 mx-sm-14 mt-md-14 mx-md-14 mb-4"
    >
      <v-img :src="getImagePath(img)"></v-img>
    </div>
    <v-card-title class="justify-center">
      <slot name="title"></slot>
    </v-card-title>
    <v-spacer></v-spacer>
    <v-dialog
      v-model="dialog"
      :width="popupWidth"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          outlined
          color="deep-purple"
          class="mb-4 mx-4"
          v-bind="attrs"
          v-on="on"
        >
          Read More
        </v-btn>
      </template>
      <v-card>
        <v-card-title>
          <span class="headline"><slot name="title"></slot></span>
        </v-card-title>
        <v-card-text>
          <slot></slot>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="primary"
            dark
            outlined
            block
            @click="dialog = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
export default {
  name: 'AboutCard',

  data() {
    return {
      dialog: false,
    };
  },

  props: {
    img: {
      type: String
    },
    minHeight: {
      type: String,
      default: '400',
    },
    popupWidth: {
      type: String,
      default: '600'
    }
  },

  methods: {
    getImagePath(src) {
      const images = require.context(
        '@/assets/',
        false,
        /\.jpg$|\.png$|\.jpeg$|\.svg$|\.tiff$|\.gif$/
      );
      return images(`./${src}`);
    }
  }
};
</script>

<style scoped>

.about-card {
  max-width: 300px;
  border-width: 0;
  background-color: #ffffffaa;
}

</style>
