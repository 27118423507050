import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        background: 'transparent',
        primary: '#F470BD',
        secondary: '#C673ED',
        accent: '#D0FC73',
        error: '#ff001e',
        info: '#C3658F',
        success: '#BBF839',
        warning: '#A9AD27',
      },
    },
  },
});
