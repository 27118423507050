<template>
  <v-main :style="{background: $vuetify.theme.themes[theme].background}">
    <div
      id="top"
    ></div>
    <v-expand-transition>
      <HypersymbolicNavBar
        fixed
        v-show="!visibleSectionTops[0]"
        :width="hsBarWidth + 'px'"
        :active-button="activeSection"
      />
    </v-expand-transition>
    <intersect @enter="$set(visibleSections, 0, true)"
               @leave="$set(visibleSections, 0, false)">
      <section
        class="full-height"
        :class="{'snap-element': snapFlags[0]}"
      >
        <HypersymbolicNavBar v-resize="updateHsBarWidth" no-logo ref="hs-page-bar"/>
        <intersect @enter="$set(visibleSectionMiddles, 0, true)"
                   @leave="$set(visibleSectionMiddles, 0, false)">
          <div class="snap-middle-marker"></div>
        </intersect>
        <v-row justify="center" no-gutters>
          <v-col cols="11" xs="11" sm="9" md="7" lg="6" xl="6">
            <div style="height: 5vh"></div>
            <intersect @enter="$set(visibleSectionTops, 0, true)"
                       @leave="$set(visibleSectionTops, 0, false)">
              <v-row
                align="center"
                justify="center"
                ref="hs-top-logo"
              >
                <v-col cols="11" sm="8" md="6">
                  <LogoWithName :scale="logoScale"/>
                </v-col>
                <v-col cols="12" sm="10" md="6">
                  <v-container fluid>
                    <span class="motto">AI to serve people</span>
                    <p class="pt-3">
                      <strong>We make the power of AI accessible to all.</strong>
                    </p>
                  </v-container>
                </v-col>
                <v-col cols="12" style="height: 1vw">
                  <div style="height: 0"></div>
                </v-col>
                <div style="height: 5vh"></div>
              </v-row>
            </intersect>
            <Intro/>
            <div class="next-section-button">
              <a href="/#productivity">
                <v-icon>mdi-chevron-down</v-icon>
              </a>
            </div>
            <intersect @enter="$set(visibleSectionBottoms, 0, true)"
                       @leave="$set(visibleSectionBottoms, 0, false)">
              <div class="snap-bottom-marker"></div>
            </intersect>
          </v-col>
        </v-row>
      </section>
    </intersect>

    <div
      id="productivity"
      class="section-anchor"
    ></div>
    <div class="section-anchor-divider"></div>
    <intersect @enter="$set(visibleSections, 1, true)"
               @leave="$set(visibleSections, 1, false)">
      <section
        class="full-height"
        :class="{'snap-element': snapFlags[1]}"
      >
        <intersect @enter="$set(visibleSectionMiddles, 1, true)"
                   @leave="$set(visibleSectionMiddles, 1, false)">
          <div class="snap-middle-marker"></div>
        </intersect>
        <intersect @enter="$set(visibleSectionTops, 1, true)"
                   @leave="$set(visibleSectionTops, 1, false)">
          <SectionTitle
            color="#E3C678"
            dark
          >
            Productivity
          </SectionTitle>
        </intersect>
        <div
          style="min-height: 80vh"
          class="d-flex flex-column justify-space-around"
        >
          <v-row justify="center" no-gutters class="productivity-section pt-3">
            <v-col class="d-flex align-center" cols="11" xs="11" sm="9" md="7" lg="6" xl="6">
              <Productivity/>
            </v-col>
          </v-row>
          <v-row justify="center" no-gutters class="productivity-section pt-3">
            <v-col class="d-flex align-center" cols="11" xs="11" sm="9" md="7" lg="6" xl="6">
              <div class="next-section-button">
                <a href="/#agriculture-environment">
                  <v-icon>mdi-chevron-down</v-icon>
                </a>
              </div>
            </v-col>
          </v-row>
        </div>
        <intersect @enter="$set(visibleSectionBottoms, 1, true)"
                   @leave="$set(visibleSectionBottoms, 1, false)">
          <div class="snap-bottom-marker"></div>
        </intersect>
      </section>
    </intersect>

    <div
      id="agriculture-environment"
      class="section-anchor"
    ></div>
    <div class="section-anchor-divider"></div>
    <intersect @enter="$set(visibleSections, 2, true)"
               @leave="$set(visibleSections, 2, false)">
      <section
        class="full-height"
        :class="{'snap-element': snapFlags[2]}"
      >
        <intersect @enter="$set(visibleSectionMiddles, 2, true)"
                   @leave="$set(visibleSectionMiddles, 2, false)">
          <div class="snap-middle-marker"></div>
        </intersect>
        <intersect @enter="$set(visibleSectionTops, 2, true)"
                   @leave="$set(visibleSectionTops, 2, false)">
          <SectionTitle
            color="#6EBC7C"
            dark
          >
            Agriculture & Environment
          </SectionTitle>
        </intersect>
        <div
          style="min-height: 80vh"
          class="d-flex flex-column justify-space-around"
        >
          <v-row justify="center" no-gutters class="agriculture-environment-section pt-3">
            <v-col class="d-flex align-center" cols="11" xs="11" sm="9" md="7" lg="6" xl="6">
              <AgricultureEnvironment/>
            </v-col>
          </v-row>
          <v-row justify="center" no-gutters class="agriculture-environment-section pt-3">
            <v-col class="d-flex align-center" cols="11" xs="11" sm="9" md="7" lg="6" xl="6">
              <div class="next-section-button">
                <a href="/#algorithmic-services">
                  <v-icon>mdi-chevron-down</v-icon>
                </a>
              </div>
            </v-col>
          </v-row>
        </div>
        <intersect @enter="$set(visibleSectionBottoms, 2, true)"
                   @leave="$set(visibleSectionBottoms, 2, false)">
          <div class="snap-bottom-marker"></div>
        </intersect>
      </section>
    </intersect>

    <div
      id="algorithmic-services"
      class="section-anchor"
    ></div>
    <div class="section-anchor-divider"></div>
    <intersect @enter="$set(visibleSections, 3, true)"
               @leave="$set(visibleSections, 3, false)">
      <section
        :class="{'snap-element': snapFlags[3]}"
      >
        <intersect @enter="$set(visibleSectionMiddles, 3, true)"
                   @leave="$set(visibleSectionMiddles, 3, false)">
          <div class="snap-middle-marker"></div>
        </intersect>
        <intersect @enter="$set(visibleSectionTops, 3, true)"
                   @leave="$set(visibleSectionTops, 3, false)">
          <SectionTitle
            color="#7FB9E5"
            dark
          >
            Algorithmic Services
          </SectionTitle>
        </intersect>
        <div
          style="min-height: 70vh"
          class="d-flex flex-column justify-space-around"
        >
          <v-row justify="center" no-gutters class="algorithmic-services-section pt-3">
            <v-col class="d-flex align-center" cols="11" xs="11" sm="9" md="7" lg="6" xl="6">
              <AlgorithmicServices/>
            </v-col>
          </v-row>
          <v-row justify="center" no-gutters class="agriculture-environment-section pt-3">
            <v-col class="d-flex align-center" cols="11" xs="11" sm="9" md="7" lg="6" xl="6">
              <div class="next-section-button">
                <a href="/#about">
                  <v-icon>mdi-chevron-down</v-icon>
                </a>
              </div>
            </v-col>
          </v-row>
        </div>
        <intersect @enter="$set(visibleSectionBottoms, 2, true)"
                   @leave="$set(visibleSectionBottoms, 2, false)">
          <div class="snap-bottom-marker"></div>
        </intersect>
      </section>
    </intersect>

    <div
      id="about"
      class="section-anchor"
    ></div>
    <div class="section-anchor-divider"></div>
    <intersect @enter="$set(visibleSections, 4, true)"
               @leave="$set(visibleSections, 4, false)">
      <section
        class="full-height"
        :class="{'snap-element': snapFlags[4]}"
      >
        <intersect @enter="$set(visibleSectionMiddles, 4, true)"
                   @leave="$set(visibleSectionMiddles, 4, false)">
          <div class="snap-middle-marker"></div>
        </intersect>
        <intersect @enter="$set(visibleSectionTops, 4, true)"
                   @leave="$set(visibleSectionTops, 4, false)">

          <SectionTitle
            color="#937B94"
            dark
          >
            About Hypersymbolic
          </SectionTitle>
        </intersect>
        <div
          style="min-height: 70vh"
          class="d-flex flex-column justify-space-around"
        >
          <div class="about-section">
            <About/>
          </div>
          <v-row justify="center" no-gutters class="about-section pt-3">
            <v-col class="d-flex align-center" cols="11" xs="11" sm="9" md="7" lg="6" xl="6">
              <div class="next-section-button">
                <a href="/#contact">
                  <v-icon>mdi-chevron-down</v-icon>
                </a>
              </div>
            </v-col>
          </v-row>
        </div>
        <intersect @enter="$set(visibleSectionBottoms, 4, true)"
                   @leave="$set(visibleSectionBottoms, 4, false)">
          <div class="snap-bottom-marker"></div>
        </intersect>
      </section>
    </intersect>

    <div
      id="contact"
      class="section-anchor"
    ></div>
    <div class="section-anchor-divider"></div>
    <intersect @enter="$set(visibleSections, 5, true)"
               @leave="$set(visibleSections, 5, false)">
      <section
        class="full-height"
        :class="{'snap-element': snapFlags[5]}"
      >
        <intersect @enter="$set(visibleSectionMiddles, 5, true)"
                   @leave="$set(visibleSectionMiddles, 5, false)">
          <div class="snap-middle-marker"></div>
        </intersect>
        <intersect @enter="$set(visibleSectionTops, 5, true)"
                   @leave="$set(visibleSectionTops, 5, false)">
          <SectionTitle
            color="#937B94"
            dark
          >
            Contact
          </SectionTitle>
        </intersect>
        <div
          style="min-height: 80vh"
          class="d-flex flex-column justify-space-around"
        >
          <div class="agriculture-environment-section pt-3">
            <Contact/>
          </div>
          <v-row justify="center" no-gutters class="contact-section pt-3">
            <v-col class="d-flex align-center" cols="11" xs="11" sm="9" md="7" lg="6" xl="6">
              <div class="next-section-button">
                <a href="/#top">
                  <v-icon>mdi-chevron-up</v-icon>
                </a>
              </div>
            </v-col>
          </v-row>
        </div>
        <intersect @enter="$set(visibleSectionBottoms, 5, true)"
                   @leave="$set(visibleSectionBottoms, 5, false)">
          <div class="snap-bottom-marker"></div>
        </intersect>
      </section>
    </intersect>

  </v-main>
</template>

<script>
import Intersect from 'vue-intersect';
import SectionTitle from '@/components/SectionTitle.vue';
import Intro from '@/components/Intro.vue';
import Productivity from '@/components/Productivity.vue';
import AlgorithmicServices from '@/components/AlgorithmicServices.vue';
import AgricultureEnvironment from '@/components/AgricultureEnvironment.vue';
import About from '@/components/About.vue';
import Contact from '@/components/Contact.vue';
import HypersymbolicNavBar from '@/components/HypersymbolicNavBar.vue';
import LogoWithName from '@/components/LogoWithName.vue';
import '@/particles';

export default {
  name: 'presentation',

  components: {
    Intersect,
    HypersymbolicNavBar,
    SectionTitle,
    LogoWithName,
    Intro,
    Productivity,
    AlgorithmicServices,
    AgricultureEnvironment,
    About,
    Contact,
  },

  data: () => ({
    sectionNames: [
      'header',
      'productivity',
      'agriculture-environment',
      'algorithmic-services',
      'about',
      'contact',
    ],
    visibleSections: [true, false, false, false, false, false],
    visibleSectionTops: [true, false, false, false, false, false],
    visibleSectionMiddles: [true, false, false, false, false, false],
    visibleSectionBottoms: [true, false, false, false, false, false],
    snapFlags: [false, false, false, false, false, false],
    hsBarWidth: 0,
    snappingUpdateDebounceDealayMs: 200,
  }),

  methods: {
    updateHsBarWidth() {
      const hsPageBar = this.$refs['hs-page-bar'];
      this.hsBarWidth = hsPageBar.$el.clientWidth;
    },
    initParticles() {
      window.particlesJS('particles-js', {
        particles: {
          number: { value: 30, max_particles: 30, density: { enable: false, value_area: 800 } },
          color: { value: '#bb52af' },
          shape: {
            type: 'circle',
            stroke: { width: 1, color: '#2a2a2a' },
            polygon: { nb_sides: 5 },
          },
          opacity: {
            value: 0.5,
            random: false,
            anim: {
              enable: false, speed: 1, opacity_min: 0.1, sync: false
            }
          },
          size: {
            value: 3,
            random: true,
            anim: {
              enable: false, speed: 40, size_min: 0.1, sync: false
            }
          },
          line_linked: {
            enable: true,
            distance: 500,
            color: '#a546cd',
            opacity: 0.4,
            width: 1
          },
          move: {
            enable: true,
            speed: 3.0,
            direction: 'none',
            random: false,
            straight: false,
            out_mode: 'bounce',
            bounce: false,
            attract: { enable: false, rotateX: 600, rotateY: 1200 }
          }
        },
        interactivity: {
          detect_on: 'window',
          events: {
            onhover: { enable: false, mode: 'repulse' }, // grab, bubble, repulse, push, remove
            onclick: { enable: true, mode: 'push' },
            resize: true
          },
          modes: {
            grab: { distance: 100, line_linked: { opacity: 0.5 } },
            bubble: {
              distance: 50, size: 10, duration: 2, opacity: 8, speed: 3
            },
            repulse: { distance: 40, duration: 0.2 },
            push: { particles_nb: 3 },
            remove: { particles_nb: 2 }
          }
        },
        retina_detect: true
      });
    },
  },

  computed: {
    theme() {
      return (this.$vuetify.theme.dark) ? 'dark' : 'light';
    },
    logoScale() {
      const scale = {
        xs: 0.7, sm: 0.77, md: 0.85, lg: 0.9, xl: 1.0
      }[this.$vuetify.breakpoint.name];
      return scale || 1.0;
    },
    activeSection() {
      const active = this.sectionNames[this.visibleSectionMiddles.indexOf(true)];
      if (active) {
        return active;
      }
      return this.sectionNames[this.visibleSections.indexOf(true)];
    }
  },

  mounted() {
    this.updateHsBarWidth();
    this.initParticles();
  },
};
</script>

<style>
.full-height {
  min-height: 105vh;
}

.snap-element {
  scroll-snap-align: start;
}

.snap-middle-marker {
  background-color: transparent;
  height: 1px;
  transform: translateY(50vh);
}

.snap-bottom-marker {
  background-color: transparent;
  height: 1px;
  transform: translateY(10px);
}

.next-section-button {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  transform: translateY(10px);
}

.next-section-button a {
  text-decoration: none;
}

.next-section-button .v-icon {
  font-size: 150px;
  color: #75757580;
}

.section-anchor {
  background-color: transparent;
  height: 1px;
}

.section-anchor-divider {
  height: 10vh;
}

.motto {
  font-size: 40px;
  font-weight: 900;
  line-height: 120%;
  color: var(--v-secondary-darken2);
}

.productivity-section {
}
.agriculture-environment-section {
}
.algorithmic-services-section {
}
</style>
