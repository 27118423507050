import Vue from 'vue';
import Router from 'vue-router';
import Presentation from './views/Presentation.vue';

Vue.use(Router);

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'presentation',
      component: Presentation
    },
    {
      path: '/contact_success',
      name: 'contact_success',
      component: () => import(/* webpackChunkName: "contactSuccess" */ './views/ContactSuccess.vue')
    },
    {
      path: '/contact_failure',
      name: 'contact_failure',
      component: () => import(/* webpackChunkName: "contactFailure" */ './views/ContactFailure.vue')
    },
    {
      path: '/legal',
      name: 'legal',
      component: () => import(/* webpackChunkName: "legal" */ './views/Legal.vue')
    }
  ]
});
