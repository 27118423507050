var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"intro"},[_c('p',[_vm._v("\n    Artificial Intelligence techniques are being developed at an accelerating\n    pace. Statistical learning, Natural Language Understanding, Image\n    Segmentation, Information Extraction, Geospatial Analytics and other\n    Machine Intelligence algorithms blur the boundary between feasible and\n    infeasible. While AI is mostly used to optimize marketing campaigns\n    and profile users, these powerful tools remain inaccessible to people.\n  ")]),_c('p',[_vm._v("\n    At Hypersymbolic, we make AI tools that will directly improve people's\n    lives and help them\n    solve their problems. We educate about AI. We harness the power of AI for\n    the benefit of all.\n  ")]),_c('v-row',{class:_vm.$vuetify.breakpoint.xlOnly ? '' : 'flex-column',attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"11","xl":"4"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('intersect',{on:{"enter":function($event){return _vm.$set(_vm.visibleCards, 0, true)},"leave":function($event){return _vm.$set(_vm.visibleCards, 0, false)}}},[_c('a',{staticStyle:{"text-decoration":"none"},attrs:{"href":"/#productivity"}},[_c('v-card',{staticClass:"mx-auto",class:{ 'on-hover': hover },attrs:{"elevation":"0"}},[_c('intersect',{on:{"enter":function($event){return _vm.$set(_vm.visibleLevelBelowFirstCard, 0, true)},"leave":function($event){return _vm.$set(_vm.visibleLevelBelowFirstCard, 0, false)}}},[_c('div',{staticStyle:{"transform":"translateY(50vh)"}})]),_c('intersect',{on:{"enter":function($event){return _vm.$set(_vm.visibleLevelBelowFirstCard, 1, true)},"leave":function($event){return _vm.$set(_vm.visibleLevelBelowFirstCard, 1, false)}}},[_c('div',{staticStyle:{"transform":"translateY(65vh)"}})]),_c('intersect',{on:{"enter":function($event){return _vm.$set(_vm.visibleLevelBelowFirstCard, 2, true)},"leave":function($event){return _vm.$set(_vm.visibleLevelBelowFirstCard, 2, false)}}},[_c('div',{staticStyle:{"transform":"translateY(80vh)"}})]),_c('v-img',{attrs:{"src":require("@/assets/backgrounds/productivity-square-1.jpg"),"aspect-ratio":_vm.$vuetify.breakpoint.xlOnly ? '1' : '3'}},[((!_vm.isTouchDevice && hover ) ||
                        (_vm.isTouchDevice &&
                         _vm.visibleLevelBelowFirstCard[0] &&
                         _vm.visibleLevelAboveLastCard[2]))?_c('div',{staticClass:"section-card-text align-center",staticStyle:{"height":"100%"}},[_c('v-card-text',{staticClass:"align-center d-flex justify-center\n                       text-h4 text-sm-h3 text-xl-h3 black--text",staticStyle:{"font-weight":"900","height":"100%","justify-content":"center"}},[_c('span',[_vm._v("Productivity")])])],1):_vm._e()])],1)],1)])]}}])})],1),_c('v-col',{attrs:{"cols":"11","xl":"4"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                         var hover = ref.hover;
return [_c('intersect',{on:{"enter":function($event){return _vm.$set(_vm.visibleCards, 1, true)},"leave":function($event){return _vm.$set(_vm.visibleCards, 1, false)}}},[_c('a',{staticStyle:{"text-decoration":"none"},attrs:{"href":"/#agriculture-environment"}},[_c('v-card',{staticClass:"mx-auto",class:{ 'on-hover': hover },attrs:{"elevation":"0"}},[_c('v-img',{attrs:{"src":require("@/assets/backgrounds/agriculture-environment-square-1.jpg"),"aspect-ratio":_vm.$vuetify.breakpoint.xlOnly ? '1' : '3'}},[((!_vm.isTouchDevice && hover ) ||
                        (_vm.isTouchDevice &&
                         _vm.visibleLevelBelowFirstCard[1] &&
                         _vm.visibleLevelAboveLastCard[1]))?_c('div',{staticClass:"section-card-text align-center",staticStyle:{"height":"100%"}},[_c('v-card-text',{staticClass:"align-center d-flex justify-center\n                       text-h4 text-sm-h3 text-xl-h3 black--text",staticStyle:{"font-weight":"900","height":"100%","justify-content":"center"}},[_c('span',[_vm._v("Agriculture &"),_c('br'),_vm._v("Environment")])])],1):_vm._e()])],1)],1)])]}}])})],1),_c('v-col',{attrs:{"cols":"11","xl":"4"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                         var hover = ref.hover;
return [_c('intersect',{on:{"enter":function($event){return _vm.$set(_vm.visibleCards, 3, true)},"leave":function($event){return _vm.$set(_vm.visibleCards, 3, false)}}},[_c('a',{staticStyle:{"text-decoration":"none"},attrs:{"href":"/#algorithmic-services"}},[_c('v-card',{staticClass:"mx-auto",class:{ 'on-hover': hover },attrs:{"elevation":"0"}},[_c('v-img',{attrs:{"src":require("@/assets/backgrounds/algorithmic-services-square-1.jpg"),"aspect-ratio":_vm.$vuetify.breakpoint.xlOnly ? '1' : '3'}},[((!_vm.isTouchDevice && hover ) ||
                        (_vm.isTouchDevice &&
                         _vm.visibleLevelBelowFirstCard[2] &&
                         _vm.visibleLevelAboveLastCard[0]))?_c('div',{staticClass:"section-card-text align-center",staticStyle:{"height":"100%"}},[_c('v-card-text',{staticClass:"align-center d-flex justify-center\n                       text-h4 text-sm-h3 text-xl-h3 black--text",staticStyle:{"font-weight":"900","height":"100%","justify-content":"center"}},[_c('span',[_vm._v("Algorithmic"),_c('br'),_vm._v("Services")])])],1):_vm._e()]),_c('intersect',{on:{"enter":function($event){return _vm.$set(_vm.visibleLevelAboveLastCard, 0, true)},"leave":function($event){return _vm.$set(_vm.visibleLevelAboveLastCard, 0, false)}}},[_c('div',{staticStyle:{"transform":"translateY(-50vh)"}})]),_c('intersect',{on:{"enter":function($event){return _vm.$set(_vm.visibleLevelAboveLastCard, 1, true)},"leave":function($event){return _vm.$set(_vm.visibleLevelAboveLastCard, 1, false)}}},[_c('div',{staticStyle:{"transform":"translateY(-65vh)"}})]),_c('intersect',{on:{"enter":function($event){return _vm.$set(_vm.visibleLevelAboveLastCard, 2, true)},"leave":function($event){return _vm.$set(_vm.visibleLevelAboveLastCard, 2, false)}}},[_c('div',{staticStyle:{"transform":"translateY(-80vh)"}})])],1)],1)])]}}])})],1)],1),_c('div',{staticStyle:{"height":"10vh"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }